import React from "react"
import GenericHero from "../components/GenericHero"
import SiteHeader from "../components/SiteHeader"
import TopImageContainer from "../components/TopImageContainer"
import TopImage from "../images/top_home_bg.png"
import SiteFooter from "../components/SiteFooter"
import content from "../data/Content"


export default function NotFound() {
  return <div className="content">
  <TopImageContainer image={TopImage}>
    <SiteHeader content={content("header")} />
    <GenericHero content={content("404")}  />
  </TopImageContainer>
  <SiteFooter content={content("footer")} />
</div> 
  
}